export const CustomSSORedirectUrl = {
  NHED_TEST: {
    url: 'https://uat.doe.nh.gov/myNHDOE/AutoStart.aspx',
    params: {
      Source: 'SystemLaunchRequest',
      UserID: '',
    },
  },
  NHED_PROD: {
    url: 'https://my.doe.nh.gov/myNHDOE/AutoStart.aspx',
    params: {
      Source: 'SystemLaunchRequest',
      UserID: '',
    },
  },
}
