import { createAction, createReducer } from 'redux-starter-kit'
import { takeEvery, takeLatest, call, put, all } from 'redux-saga/effects'
import { settingsApi, canvasApi } from '@edulastic/api'
import { notification } from '@edulastic/common'
import { get } from 'lodash'
import { createSelector } from 'reselect'
import { getUserRole } from '../src/selectors/user'

// action types
const RECEIVE_DISTRICT_POLICY_REQUEST = '[district policy] receive data request'
const RECEIVE_DISTRICT_POLICY_SUCCESS = '[district policy] receive data success'
const RECEIVE_DISTRICT_POLICY_ERROR = '[district policy] receive data error'
const UPDATE_DISTRICT_POLICY_REQUEST = '[district policy] update data request'
const UPDATE_DISTRICT_POLICY_SUCCESS = '[district policy] update data success'
const UPDATE_DISTRICT_POLICY_ERROR = '[district policy] update data error'
const CREATE_DISTRICT_POLICY_REQUEST = '[district policy] create data request'
const CREATE_DISTRICT_POLICY_SUCCESS = '[district policy] create data success'
const CREATE_DISTRICT_POLICY_ERROR = '[district policy] create data error'
const SET_SCHOOL_ADMIN_SETTINGS_ACCESS =
  '[district policy] set school admin settings access'
const FETCH_CANVAS_ACCOUNTS_DATA_REQUEST =
  '[school mapping] receive canvas accounts data request'
const FETCH_CANVAS_USER_DATA_SUCCESS =
  '[school mapping] receive canvas data success'
const FETCH_CANVAS_USER_DATA_FAILURE =
  '[school mapping] receive canvas data failure'

const FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS =
  '[school mapping] receive pear assessment schools'
const FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS_SUCCESS =
  '[school mapping] receive pear assessment schools success'
const FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS_FAILURE =
  '[school mapping] failed to receive pear assessment schools '
const REINITIALIZE_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS =
  '[school mapping] reinitalizing pear assessment school states'
const UPDATE_SCHOOL_MAPPING_DATA_REQUEST =
  '[school mapping update] update data request'
const UPDATE_SCHOOL_MAPPING_DATA_REQUEST_SUCCESS =
  '[school mapping update] update data success'

const CHANGE_DISTRICT_POLICY_ACTION = '[district policy] save changed data'
const SAVE_CANVAS_INTEGRATION_KEYS_REQUEST =
  '[district policy] save canvas integration keys request'

const SAVE_CANVAS_CONFIG_REQUEST =
  '[district policy] save canvas config request'

const CANVAS_BANNER_CLOSED = '[district policy] canvas banner close '

const TRIGGER_CANVAS_DISTRICT_SYNC_REQUEST =
  '[canvas district sync] trigger sync request'

const TRIGGER_CANVAS_DISTRICT_SYNC_SUCCESS =
  '[canvas district sync] trigger sync success'

export const receiveDistrictPolicyAction = createAction(
  RECEIVE_DISTRICT_POLICY_REQUEST
)
export const receiveSchoolPolicyAction = (schoolId) =>
  receiveDistrictPolicyAction({ orgType: 'institution', orgId: schoolId })
export const receiveDistrictPolicySuccessAction = createAction(
  RECEIVE_DISTRICT_POLICY_SUCCESS
)
export const receiveDistrictPolicyErrorAction = createAction(
  RECEIVE_DISTRICT_POLICY_ERROR
)
export const updateDistrictPolicyAction = createAction(
  UPDATE_DISTRICT_POLICY_REQUEST
)
export const updateDistrictPolicySuccessAction = createAction(
  UPDATE_DISTRICT_POLICY_SUCCESS
)
export const updateDistrictPolicyErrorAction = createAction(
  UPDATE_DISTRICT_POLICY_ERROR
)
export const createDistrictPolicyAction = createAction(
  CREATE_DISTRICT_POLICY_REQUEST
)
export const createDistrictPolicySuccessAction = createAction(
  CREATE_DISTRICT_POLICY_SUCCESS
)
export const createDistrictPolicyErrorAction = createAction(
  CREATE_DISTRICT_POLICY_ERROR
)

export const changeDistrictPolicyAction = createAction(
  CHANGE_DISTRICT_POLICY_ACTION
)

export const saveCanvasKeysRequestAction = createAction(
  SAVE_CANVAS_INTEGRATION_KEYS_REQUEST
)

export const saveCanvasConfigRequestAction = createAction(
  SAVE_CANVAS_CONFIG_REQUEST
)

export const setSchoolAdminSettingsAccessAction = createAction(
  SET_SCHOOL_ADMIN_SETTINGS_ACCESS
)
export const fetchCanvasAccountsAction = createAction(
  FETCH_CANVAS_ACCOUNTS_DATA_REQUEST
)
export const receiveSchoolMappingSuccessAction = createAction(
  FETCH_CANVAS_USER_DATA_SUCCESS
)
export const receiveSchoolMappingFailure = createAction(
  FETCH_CANVAS_USER_DATA_FAILURE
)
export const updateSchoolMappingAction = createAction(
  UPDATE_SCHOOL_MAPPING_DATA_REQUEST
)
export const updateSchoolMappingSuccessAction = createAction(
  UPDATE_SCHOOL_MAPPING_DATA_REQUEST_SUCCESS
)

export const canvasDistrictSyncAction = createAction(
  TRIGGER_CANVAS_DISTRICT_SYNC_REQUEST
)

export const canvasDistrictSyncSuccessAction = createAction(
  TRIGGER_CANVAS_DISTRICT_SYNC_SUCCESS
)

export const fetchPearAssessmentSchoolsForCanvasUsers = createAction(
  FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS
)
export const fetchPearAssessmentSchoolsForCanvsUsersSuccess = createAction(
  FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS_SUCCESS
)
export const fetchPearAssessmentSchoolsForCanvasUsersError = createAction(
  FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS_FAILURE
)
export const reinitiazlePearAssessmentSchoolsForCanvasUsersAction = createAction(
  REINITIALIZE_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS
)

export const closeCanvasBannerAction = createAction(CANVAS_BANNER_CLOSED)
// reducers
const initialState = {
  data: {},
  error: null,
  loading: false,
  updating: false,
  update: null,
  updateError: null,
  creating: false,
  createError: null,
  userNameAndPassword: true,
  googleSignOn: true,
  office365SignOn: true,
  cleverSignOn: true,

  teacherSignUp: true,
  studentSignUp: true,

  searchAndAddStudents: false,
  manualEnrollmentAllowed: true,

  googleUsernames: true,
  office365Usernames: true,
  firstNameAndLastName: true,

  allowedDomainForStudents: '',
  allowedDomainForTeachers: '',
  allowedDomainsForDistrict: '',

  canvas: false,

  schoolAdminSettingsAccess: false,

  schoolMappingReducer: {
    fetchingCanvasUserData: false,
    canvasAccountsData: {},
    fetchCanvasDataError: null,
    pearAssessmentSchoolsForCanvasUser: {},
    fetchingPearAssessmentSchoolsForCanvasUser: false,
    fetchPearAssessmentSchoolsSuccess: null,
    fetchPearAssessmentSchoolsError: null,
    updateSchoolMappingDataSuccess: null,
    updatingSchoolMappingData: false,
  },
  isCanvasBannerClosed: false,
}

export const reducer = createReducer(initialState, {
  [RECEIVE_DISTRICT_POLICY_REQUEST]: (state) => {
    state.loading = true
  },
  [RECEIVE_DISTRICT_POLICY_SUCCESS]: (state, { payload: _payload }) => {
    const { schoolLevel, ...payload } = _payload
    state.loading = false
    state[schoolLevel ? 'schoolData' : 'data'] = {
      ...payload,
      allowedDomainForStudents: get(
        payload,
        ['allowedDomainForStudents'],
        ''
      ).toString(),
      allowedDomainForTeachers: get(
        payload,
        ['allowedDomainForTeachers'],
        ''
      ).toString(),
      allowedDomainsForDistrict: get(
        payload,
        ['allowedDomainsForDistrict'],
        ''
      ).toString(),
    }
  },
  [RECEIVE_DISTRICT_POLICY_ERROR]: (state, { payload }) => {
    state.loading = false
    state.error = payload.error
  },
  [SAVE_CANVAS_CONFIG_REQUEST]: (state) => {
    state.updating = true
  },
  [UPDATE_DISTRICT_POLICY_REQUEST]: (state) => {
    state.updating = true
  },
  [UPDATE_DISTRICT_POLICY_SUCCESS]: (state, { payload }) => {
    state.updating = false
    state[payload.orgType === 'institution' ? 'schoolData' : 'data'] = {
      ...payload,
      allowedDomainForStudents: get(
        payload,
        ['allowedDomainForStudents'],
        ''
      ).toString(),
      allowedDomainForTeachers: get(
        payload,
        ['allowedDomainForTeachers'],
        ''
      ).toString(),
      allowedDomainsForDistrict: get(
        payload,
        ['allowedDomainsForDistrict'],
        ''
      ).toString(),
    }
  },
  [UPDATE_DISTRICT_POLICY_ERROR]: (state, { payload }) => {
    state.updating = false
    state.updateError = payload.error
  },
  [CREATE_DISTRICT_POLICY_REQUEST]: (state) => {
    state.creating = true
  },
  [CREATE_DISTRICT_POLICY_SUCCESS]: (state, { payload }) => {
    state.creating = false
    state.data = {
      ...payload,
      allowedDomainForStudents: payload.allowedDomainForStudents.toString(),
      allowedDomainForTeachers: payload.allowedDomainForTeachers.toString(),
      allowedDomainsForDistrict: payload.allowedDomainsForDistrict.toString(),
    }
  },
  [CREATE_DISTRICT_POLICY_ERROR]: (state, { payload }) => {
    state.creating = false
    state.createError = payload.error
  },
  [CHANGE_DISTRICT_POLICY_ACTION]: (state, { payload }) => {
    const { schoolLevel, ...data } = payload
    if (schoolLevel) {
      state.schoolData = data
    } else {
      state.data = data
    }
  },
  [SET_SCHOOL_ADMIN_SETTINGS_ACCESS]: (state, { payload }) => {
    state.schoolAdminSettingsAccess = payload
  },
  [FETCH_CANVAS_ACCOUNTS_DATA_REQUEST]: (state) => {
    state.schoolMappingReducer.fetchingCanvasUserData = true
  },
  [FETCH_CANVAS_USER_DATA_SUCCESS]: (state, payload) => {
    state.schoolMappingReducer.fetchingCanvasUserData = false
    state.schoolMappingReducer.canvasAccountsData = payload
  },
  [FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS]: (state) => {
    state.schoolMappingReducer.fetchingPearAssessmentSchoolsForCanvasUser = true
    state.schoolMappingReducer.fetchPearAssessmentSchoolsSuccess = null
    state.schoolMappingReducer.pearAssessmentSchoolsForCanvasUser = {}
  },
  [FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS_SUCCESS]: (
    state,
    { payload }
  ) => {
    state.schoolMappingReducer.fetchingPearAssessmentSchoolsForCanvasUser = false
    state.schoolMappingReducer.fetchPearAssessmentSchoolsSuccess = true
    state.schoolMappingReducer.pearAssessmentSchoolsForCanvasUser = payload
  },
  [FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS_FAILURE]: (
    state,
    { payload }
  ) => {
    state.schoolMappingReducer.fetchingPearAssessmentSchoolsForCanvasUser = false
    state.schoolMappingReducer.fetchPearAssessmentSchoolsSuccess = false
    state.schoolMappingReducer.fetchPearAssessmentSchoolsError = payload
  },
  [REINITIALIZE_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS]: (state) => {
    state.schoolMappingReducer.fetchingPearAssessmentSchoolsForCanvasUser = false
    state.schoolMappingReducer.fetchPearAssessmentSchoolsSuccess = null
    state.schoolMappingReducer.pearAssessmentSchoolsForCanvasUser = {}
  },

  [FETCH_CANVAS_USER_DATA_FAILURE]: (state, { payload }) => {
    state.schoolMappingReducer.fetchingCanvasUserData = false
    state.schoolMappingReducer.fetchCanvasDataError = payload
  },
  [UPDATE_SCHOOL_MAPPING_DATA_REQUEST]: (state) => {
    state.schoolMappingReducer.updatingSchoolMappingData = true
  },
  [UPDATE_SCHOOL_MAPPING_DATA_REQUEST_SUCCESS]: (state, { payload }) => {
    state.schoolMappingReducer.updatingSchoolMappingData = false
    state.schoolMappingReducer.updateSchoolMappingDataSuccess = payload
  },
  [CANVAS_BANNER_CLOSED]: (state) => {
    window.localStorage.setItem('isCanvasBannerClosed', true)
    state.isCanvasBannerClosed = true
  },
  [TRIGGER_CANVAS_DISTRICT_SYNC_SUCCESS]: (state) => {
    state.isCanvasDistrictSyncTriggred = true
  },
})

// saga
function* receiveDistrictPolicySaga({ payload }) {
  try {
    const schoolLevel = payload.orgType === 'institution'
    const districtPolicy = yield call(settingsApi.getDistrictPolicy, payload)
    yield put(
      receiveDistrictPolicySuccessAction({ ...districtPolicy, schoolLevel })
    )
  } catch (err) {
    const errorMessage = 'Unable to retrieve District policy.'
    notification({ type: 'error', msg: errorMessage })

    yield put(receiveDistrictPolicyErrorAction({ error: errorMessage }))
  }
}

function* updateDictrictPolicySaga({ payload }) {
  try {
    const updateDistrictPolicy = yield call(
      settingsApi.updateDistrictPolicy,
      payload
    )
    notification({ type: 'success', messageKey: 'SavedSuccessfully' })
    yield put(updateDistrictPolicySuccessAction({ ...updateDistrictPolicy }))
  } catch (err) {
    const errorMessage = 'Unable to update District policy.'
    notification({ type: 'error', msg: errorMessage })
    yield put(updateDistrictPolicyErrorAction({ error: errorMessage }))
  }
}

function* createDictrictPolicySaga({ payload }) {
  try {
    const createDistrictPolicy = yield call(
      settingsApi.createDistrictPolicy,
      payload
    )
    yield put(createDistrictPolicySuccessAction(createDistrictPolicy))
  } catch (err) {
    const errorMessage = 'Unable to create District policy.'
    notification({ type: 'error', msg: errorMessage })
    yield put(createDistrictPolicyErrorAction({ error: errorMessage }))
  }
}

function* saveCanvasKeysRequestSaga({ payload }) {
  try {
    const result = yield call(settingsApi.saveCanvasIntegrationKeys, payload)
    yield put(updateDistrictPolicySuccessAction(result))
    notification({
      type: 'success',
      msg: 'Canvas Integration keys saved successfully',
    })
  } catch (err) {
    const notificationData = {
      type: 'error',
      msg:
        err?.response?.data?.message ||
        'Unable to save Canvas Integration keys',
    }
    if (err?.response?.data?.statusCode === 403) {
      Object.assign(notificationData, {
        exact: true,
      })
    }
    notification(notificationData)
  }
}

function* saveCanvasConfigRequestSaga({ payload }) {
  try {
    const result = yield call(settingsApi.saveCanvasConfig, payload)
    yield put(updateDistrictPolicySuccessAction(result))
    notification({
      type: 'success',
      msg: 'Canvas Preferences saved successfully',
    })
  } catch (err) {
    const errorMessage = 'Unable to save Canvas Preferences'
    const notificationData = {
      type: 'error',
      msg: errorMessage,
    }
    notification(notificationData)
    yield put(updateDistrictPolicyErrorAction({ error: errorMessage }))
  }
}

function* fetchCanvasAccountsRequestSaga(payload) {
  try {
    const canvasAccountsData = yield call(canvasApi.getCanvasAccounts, payload)
    yield put(
      receiveSchoolMappingSuccessAction(canvasAccountsData?.data?.result)
    )
    if (!canvasAccountsData?.data?.result?.canvasAccountsData?.length) {
      notification({
        type: 'warn',
        msg:
          'Fetching account details failed. Please use your Canvas admin account to proceed.',
      })
    }
  } catch (error) {
    notification({
      type: 'error',
      msg: error.message,
    })
    yield put(receiveSchoolMappingFailure(error))
  }
}

function* updateSchoolMappingForCanvasSync({ payload }) {
  try {
    yield call(canvasApi.saveSchoolMappingForCanvasSync, payload)
    yield put(updateSchoolMappingSuccessAction(true))
    notification({
      type: 'success',
      msg: 'Data saved successfully',
    })
    yield put(reinitiazlePearAssessmentSchoolsForCanvasUsersAction())
  } catch (error) {
    yield put(updateSchoolMappingSuccessAction(false))
    notification({
      type: 'error',
      msg: error,
    })
  }
}
function* receivePearAssessmentSchoolsForCanvasUser({ payload }) {
  try {
    const result = yield call(
      canvasApi.getSchoolMappingForCanvasAccount,
      payload
    )
    yield put(
      fetchPearAssessmentSchoolsForCanvsUsersSuccess(result.data?.result)
    )
  } catch (error) {
    yield put(fetchPearAssessmentSchoolsForCanvasUsersError(error))
  }
}

function* canvasDistrictSync({ payload }) {
  try {
    notification({
      type: 'success',
      msg:
        'District sync may take some time to complete. You will be notified once the sync is finished.',
    })
    const { data } = yield call(canvasApi.canvasDistrictSync, payload)
    yield put({
      type: TRIGGER_CANVAS_DISTRICT_SYNC_SUCCESS,
    })
    if (data?.result?.message) {
      notification({
        type: data?.result?.type,
        msg: data?.result?.message,
      })
    }
  } catch (error) {
    notification({
      type: 'error',
      msg: error,
    })
  }
}

export function* watcherSaga() {
  yield all([
    takeLatest(RECEIVE_DISTRICT_POLICY_REQUEST, receiveDistrictPolicySaga),
  ])
  yield all([
    takeEvery(UPDATE_DISTRICT_POLICY_REQUEST, updateDictrictPolicySaga),
  ])
  yield all([
    takeEvery(CREATE_DISTRICT_POLICY_REQUEST, createDictrictPolicySaga),
  ])
  yield all([
    takeEvery(SAVE_CANVAS_INTEGRATION_KEYS_REQUEST, saveCanvasKeysRequestSaga),
  ])
  yield all([
    takeLatest(SAVE_CANVAS_CONFIG_REQUEST, saveCanvasConfigRequestSaga),
  ])
  yield all([
    takeEvery(
      FETCH_CANVAS_ACCOUNTS_DATA_REQUEST,
      fetchCanvasAccountsRequestSaga
    ),
  ])
  yield all([
    takeEvery(
      UPDATE_SCHOOL_MAPPING_DATA_REQUEST,
      updateSchoolMappingForCanvasSync
    ),
  ])
  yield all([
    takeEvery(
      FETCH_PEAR_ASSESSMENT_SCHOOLS_FOR_CANVAS_USERS,
      receivePearAssessmentSchoolsForCanvasUser
    ),
  ])
  yield all([
    takeEvery(TRIGGER_CANVAS_DISTRICT_SYNC_REQUEST, canvasDistrictSync),
  ])
}

export const getSchoolPolicy = (state) =>
  get(state, ['districtPolicyReducer', 'schoolData'], [])
export const getDistrictPolicy = (state) =>
  get(state, ['districtPolicyReducer', 'data'], {})
export const schoolMappingReducer = (state) =>
  get(state, ['districtPolicyReducer', 'schoolMappingReducer'], {})

export const getPolicies = createSelector(
  getUserRole,
  getSchoolPolicy,
  getDistrictPolicy,
  (role, schoolPolicy = {}, districtPolicy = {}) =>
    role === 'school-admin' ? schoolPolicy : districtPolicy
)

export const getSchoolAdminSettingsAccess = createSelector(
  getPolicies,
  (state) => state.schoolAdminSettingsAccess
)

export const getEnableOneRosterSync = createSelector(
  getDistrictPolicy,
  (state) => state.enableOneRosterSync || false
)

export const getManualEnrollmentAllowed = createSelector(
  getSchoolPolicy,
  (state) => state.manualEnrollmentAllowed ?? true
)

export const getCanvasAccountsData = createSelector(
  schoolMappingReducer,
  (state) => state.canvasAccountsData
)
export const fetchCanvasUserDataLoading = createSelector(
  schoolMappingReducer,
  (state) => state.fetchingCanvasUserData
)
export const fetchCanvasUserDataError = createSelector(
  schoolMappingReducer,
  (state) => state.fetchCanvasDataError
)
export const getUpdatingStatusForSchoolCanvasSync = createSelector(
  schoolMappingReducer,
  (state) => state.updatingSchoolMappingData
)
export const getUpdatingSuccessStatusForSchoolCanvasSync = createSelector(
  schoolMappingReducer,
  (state) => state.updateSchoolMappingDataSuccess
)
export const getSchoolMappingForCanvasAccount = createSelector(
  schoolMappingReducer,
  (state) => state.pearAssessmentSchoolsForCanvasUser
)
export const getPearAssessmentSchoolsForCanvasUserLoading = createSelector(
  schoolMappingReducer,
  (state) => state.fetchingPearAssessmentSchoolsForCanvasUser
)
export const fetchPearSchoolsSuccessStatus = createSelector(
  schoolMappingReducer,
  (state) => state.fetchPearAssessmentSchoolsSuccess
)

export const getDistrictPolicyCanvas = createSelector(
  getDistrictPolicy,
  (districtPolicy) => districtPolicy.canvas
)

export const getIsCanvasBannerClosed = (state) =>
  get(state, ['districtPolicyReducer', 'isCanvasBannerClosed'], [])

export const getIsDistrictPolicyUpdatingSelector = (state) =>
  get(state, ['districtPolicyReducer', 'updating'], false)

export const getIsCanvasBannerClose = createSelector(
  getIsCanvasBannerClosed,
  (isCanvasBannerClosed) =>
    JSON.parse(window.localStorage.getItem('isCanvasBannerClosed')) ??
    isCanvasBannerClosed
)
